import React from 'react';
import './qrCss.css';
import QRCodeGenerator from '../utility/component/qrCodegenerater';
import moment from 'moment';

const QrCodeSlip = React.forwardRef((props, ref) => {
  const { value, item, selectedQr } = props;

  const list = item.filter((o) => o?.CUSTOMER_NAME);
  let ind = 0;
  let Upper_lower = list[0] ? list[0].UPPER_LOWER : 'UPPER';

  return (
    <div id="print-container" ref={ref}>
      {list.map((data, index) => {
        let qrValue = '';

        if (Upper_lower === data.UPPER_LOWER) {
          qrValue = `${value}_${data.UPPER_LOWER}_${data.ICODE}_${ind + 1}`;

          ind = ind + 1;
        } else {
          Upper_lower = data.UPPER_LOWER;
          ind = 1;
          qrValue = `${value}_${data.UPPER_LOWER}_${data.ICODE}_${0 + 1}`;
        }
        const customerName = data?.CUSTOMER_NAME?.split(' ');
        const customerWithTwoWords = customerName[0] + ' ' + customerName[1];
        const customer = data?.CUSTOMER_NAME
          ? data?.CUSTOMER_NAME.length >= 15
            ? customerWithTwoWords.length >= 15
              ? customerName[0].toUpperCase().slice(0, 15)
              : customerWithTwoWords.toUpperCase().slice(0, 15)
            : data?.CUSTOMER_NAME
          : '';

        return (
          <div key={index} className="print-page   w-[138px]">
            <div className="qr-slip ">
              <div className="h-[190px]"></div>
              <div className="flex flex-col w-full justify-center border-b pb-[12px] border-black items-center">
                <div
                  className={` ${'text-[17px]'} font-semibold  text-center whitespace-nowrap`}
                >
                  {customer}
                </div>
                <div className="font-semibold text-[17px] text-center">
                  {data?.PREFIX}-{data?.MRC_NO}
                </div>
                <div className="font-semibold text-[17px] text-center">
                  {data?.ITEM_NAME?.toUpperCase().slice(0, 12) || ''}
                </div>
                <div className="font-semibold text-[17px] flex justify-center w-full text-center">
                  {moment(data?.DATE).format('DD-MM-YYYY')}
                </div>
              </div>
              <div className="pt-[16px] border-black ">
                <QRCodeGenerator value={selectedQr ? selectedQr : qrValue} />
              </div>
              <div className="text-[13px] flex justify-center w-full text-center">
                {selectedQr ? selectedQr : qrValue}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default QrCodeSlip;
