import React, { useEffect, useState } from 'react';
import ViewBillImage from './modals/viewBill';
import {
  BillingBackButton,
  DownoaddButtonInvoice,
  PrintButtonInvoice,
  Whatsapp_button,
} from './buttons';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBillInvoiceId, setViewBillMod } from '../Redux/billing';
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
} from '../Redux/profile';
import { selectToken } from '../Redux/security';
import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../Redux/modal';
import Lottie from 'lottie-react';
import spinner from '../../utility/json/Spinner.json';
import { toJpeg, toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { Button } from '@mui/material';
import { FormatNumber2 } from './tools';
import { DownloadFile, UploadFile } from './functions/firebase';
import { getMessageWallet } from '../func/common';
import { not_permisson_forWhastapp } from '../common';
const ViewBill = () => {
  const componentRef = useRef();
  const prefix = useSelector(selectPrefix);
  const billInvoice = useSelector(selectBillInvoiceId);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [billSettlement, setBillSettlement] = useState([]);
  const [list, setList] = useState([]);
  const companyDetails = useSelector(selectCompanyAddress);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const getViewBillDetatils = () => {
    axios
      .post(
        '/api/billing/loadBill',

        {
          table_prefix: prefix,
          INVNO: billInvoice,
        },

        {
          headers: headers,
        }
      )

      .then((response) => {
        const dataList = response.data.response;
        console.log(dataList);
        setList(dataList);
        setDate(dataList[0].DATE);
        getViewBillSettelment();
      })
      .catch((err) => {
        if (err.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
    // .finally(() => {
    //   dispatch(setloading(false));
    // });
  };

  const getViewBillSettelment = () => {
    axios
      .post(
        '/api/billing/loadBillSettlement',

        {
          table_prefix: prefix,
          INVNO: billInvoice,
        },

        {
          headers: headers,
        }
      )

      .then((response) => {
        if (response.data.status === 200) {
          setBillSettlement(response.data.response);
        }
      })
      .catch((err) => {
        if (err.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(err);
      });
    // .finally(() => {
    //   dispatch(setloading(false));
    // });
  };

  const downloadPdf = () => {
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        pdf.save(`Bill_${billInvoice}`);
      })
      .catch((err) => console.log(err));
  };

  const defaultProfile = useSelector(selectDefultProfileSetting);
  function dataURLToBlob(dataUrl) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }
  const sendWhatsapp = (number, total) => {
    if (defaultProfile?.SEND_WHATSAPP === 'Y') {
      dispatch(setSpinnerLoading('sending image'));

      getMessageWallet(companyDetails.email, token)
        .then((bal) => {
          if (bal > 0) {
            if (number?.length === 10) {
              const amount = FormatNumber2(total);
              toJpeg(
                componentRef.current,
                window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
              )
                .then((dataUrl) => {
                  const blob = dataURLToBlob(dataUrl);
                  const path = 'tailoringx/bill/';
                  const name = `${prefix + billInvoice}`;
                  UploadFile(blob, path, name)
                    .then(() => {
                      const b = `tailoringx/bill/${prefix + billInvoice}`;
                      DownloadFile(b)
                        .then((c) => {
                          const s = c.split(
                            'https://firebasestorage.googleapis.com/v0/b/genius-office.appspot.com/'
                          );
                          const data = {
                            dataUrl: s[1],
                            invno: billInvoice.toString(),
                            mobile: number,
                            amount: amount,
                            company_email: companyDetails.email,
                            accountSid: 'AC3d8fd1bb1700f4445c0491d89457a403',
                            authToken: 'bf73d1ae8b3691a3b84aaf8dbe9a8673',
                            from: 'MG33710709a90ac2cb41f7ed79e7b717d8',
                            contentSid: 'HXca99ee8943144718660df0ed242caadd',
                          };
                          axios
                            .post('/api/sendDigitalBill/saveBillImage', data, {
                              headers: headers,
                            })
                            .then((response) => {
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Success',
                                  msg: 'Whatsapp is sent succesfully',
                                  lottie: 'success',
                                })
                              );
                            })
                            .catch((err) => {
                              console.log(err);

                              if (
                                err?.response?.data?.name ===
                                'TokenExpiredError'
                              ) {
                                dispatch(setSessionExpired(true));
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Whatsapp Sent',
                                    msg: 'Whastapp sent successfully',
                                  })
                                );
                              } else {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'ERROR',
                                    msg: 'Something went wrong.',
                                    lottie: 'reject',
                                  })
                                );
                              }
                            })
                            .finally(() => {
                              dispatch(setSpinnerLoading(false));
                            });
                        })
                        .catch(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                      dispatch(setSpinnerLoading(false));
                    });
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(setSpinnerLoading(false));
                });
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Incorrect fields',
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Insufficient balance',
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Insufficient balance',
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: not_permisson_forWhastapp,
        })
      );
    }
  };
  useEffect(() => {
    getViewBillDetatils();
  }, []);
  const handleSendSms = (number, amount) => {
    if (defaultProfile?.SEND_SMS === 'Y') {
      dispatch(setSpinnerLoading('Sending'));
      getMessageWallet(companyDetails.email, token, true)
        .then((bal) => {
          if (bal > 0) {
            if (number?.length === 10 && amount) {
              const data = {
                INVNO: billInvoice,
                MOBILE: `+91${number}`,
                AMOUNT: amount,
                company_email: companyDetails.email,
                BILL_URL: '',
              };
              axios
                .post(
                  '/api/sendDigitalBill/sendSms',
                  data,

                  {
                    headers: headers,
                  }
                )
                .then((response) => {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Msg Sent',
                      msg: 'Message sent successfully',
                    })
                  );
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error',
                      msg: 'Message Failed',
                    })
                  );
                })
                .finally(() => {
                  dispatch(setSpinnerLoading(false));
                });
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Incorrect fields',
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Insufficient funds',
              })
            );
          }
        })
        .catch(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: not_permisson_forWhastapp,
        })
      );
    }
  };
  return (
    <div className="flex ">
      {billSettlement[0] ? (
        <div className="flex">
          <div>
            <ViewBillImage
              ref={componentRef}
              billSettlement={billSettlement}
              list={list}
              date={date}
              billInvoice={billInvoice}
            />
          </div>
          <div className="p-3  ">
            <div className="bg-[#cfb28c] rounded-md w-full  ">
              <input
                placeholder="Mobile"
                type="number"
                value={list[0]?.MOBILE}
                className="w-full h-full bg-[#f5ede4] py-2 text-md outline-0 placeholder:text-sm pl-3 placeholder:text-black rounded-md"
              />
            </div>
            <div className="mt-2">
              <Whatsapp_button
                onClick={() => {
                  sendWhatsapp(list[0]?.MOBILE, list[0]?.GTOTAL);
                }}
              />
            </div>
            <div className="mt-2">
              <Button
                onClick={() => {
                  handleSendSms(list[0]?.MOBILE, list[0]?.GTOTAL);
                }}
                variant="contained"
                style={{
                  textTransform: 'none',
                  width: '100%',

                  backgroundColor: '#f5874f',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    fontWeight: '400',
                  }}
                  className="text-white text-[15px] px-5 flex items-center "
                >
                  SMS{' '}
                </div>
              </Button>
            </div>
            <div
              style={{
                borderTop: '1px dashed gray',
                marginInline: '16px',
              }}
              className="mt-3 "
            />

            <div className="mt-3">
              <ReactToPrint
                trigger={() => {
                  return <PrintButtonInvoice />;
                }}
                content={() => componentRef.current}
              />
            </div>
            <div className="mt-2">
              <DownoaddButtonInvoice onClick={downloadPdf} />
            </div>
            <div className="mt-2">
              <BillingBackButton
                onClick={() => dispatch(setViewBillMod(false))}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full   flex justify-center items-center h-full p-3  ">
          <div className="w-7 h-7  flex items-center">
            <Lottie
              animationData={spinner}
              loop={true}
              style={{
                fontSize: 15,
                marginTop: '15px',
              }}
              color="white"
            />
          </div>
          <div className="pl-2 text-sm"> Loading..</div>
        </div>
      )}
    </div>
  );
};

export default ViewBill;
