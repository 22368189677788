import { createSlice } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';
import moment from 'moment';

const prefixEncrypted = localStorage.getItem('prefix');
const userTypeEncrypted = localStorage.getItem('userType');
const userCodeEnc = localStorage.getItem('userCode');
const isProAlowed = localStorage.getItem('isProductionAllow');
const isWhatsAppAllowed = localStorage.getItem('isWhatsAppAllow');

const station_id = localStorage.getItem('stationId');
const userName = localStorage.getItem('userName');
const stationName = localStorage.getItem('stationName');
const emailencryted = localStorage.getItem('email');
const companyAddress = JSON.parse(localStorage.getItem('companyDetails'));
const secretPass = 'kasdaksjfdsaask';
const getPrefix = () => {
  if (prefixEncrypted) {
    const bytes = CryptoJS.AES.decrypt(prefixEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var prefix = data;

      return prefix;
    }
  }

  return null;
};

const isWhatsAppAllowedFucn = () => {
  if (isWhatsAppAllowed) {
    const bytes = CryptoJS.AES.decrypt(prefixEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var prefix = data;

      return prefix;
    }
  }

  return null;
};

const getIsProduction = () => {
  if (isProAlowed) {
    const bytes = CryptoJS.AES.decrypt(isProAlowed, 'goseam@2023');
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = bytes.toString(CryptoJS.enc.Utf8);

      var prefix = data;

      return prefix;
    }
  }

  return null;
};

const masterPrefixEncrypted = localStorage.getItem('masterPrefix');
const getMasterPrefix = () => {
  if (masterPrefixEncrypted) {
    const bytes = CryptoJS.AES.decrypt(masterPrefixEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const d = bytes.toString(CryptoJS.enc.Utf8);
      if (d) {
        const data = JSON.parse(d);
        var prefix = data;

        return prefix;
      } else {
        localStorage.clear();
      }
    }
  }

  return null;
};

const getUserType = () => {
  if (userTypeEncrypted) {
    const bytes = CryptoJS.AES.decrypt(userTypeEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var userType = data;

      return userType;
    }
  }

  return null;
};
const getUserCcode = () => {
  if (userCodeEnc) {
    const bytes = CryptoJS.AES.decrypt(userCodeEnc, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var userType = data;

      return userType;
    }
  }

  return null;
};
const GuestDetails = () => {
  const Guest = localStorage.getItem('BillingGuestDetails');
  if (Guest) {
    return JSON.parse(Guest);
  } else {
    return {
      guestName: '',
      guestMobile: '',
      guestEmail: '',
    };
  }
};

const FinancialDetails = () => {
  const Financial = localStorage.getItem('financial');
  if (Financial) {
    return JSON.parse(Financial);
  } else {
    return {
      id: '',
      year: '',
      start_date: '',
      end_date: '',
      company_code: '',
      company_name: '',
      year_closed: '',
      name: '',
    };
  }
};

const getUserEmail = () => {
  if (emailencryted) {
    const bytes = CryptoJS.AES.decrypt(emailencryted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var email = data;

      return email;
    }
  }

  return null;
};

const profileIntialState = {
  userName: userName,
  userCode: getUserCcode(),
  // prefix: 'ct_20232024_',
  prefix: getPrefix(),
  masterPrefix: getMasterPrefix(),
  // masterPrefix: 'ct_',
  todayIssueStatus: false,
  companyAddress: companyAddress,
  userType: getUserType(),
  companyCity: '',
  billingGuest: GuestDetails(),
  billIngAccount: {
    accNumber: '',
    accMobile: '',
    accName: '',
  },
  isProductionModuleAlowed: getIsProduction() === 'Y' ? true : false,
  isWhatsAppAllow: isWhatsAppAllowedFucn() === 'Y' ? true : false,
  pathList: [],
  pathHistory: [],
  profileDefaultSetting: '',
  station_id: station_id,
  stationName: stationName,
  latitude: '',
  departMentName: '',
  longitude: '',
  isFullScreen: false,
  date: moment().format('YYYY-MM-DD'),
  financialYearDetails: FinancialDetails(),
  userEmail: getUserEmail(),
  windowWidth: window.innerWidth,
  firebaseStatusField: localStorage.getItem('statusField'),
};

export const Profile = createSlice({
  name: 'profile',
  initialState: profileIntialState,
  reducers: {
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setUserCode(state, action) {
      state.userCode = action.payload;
    },
    setIsProductionModuleAllowed(state, action) {
      state.isProductionModuleAlowed = action.payload;
    },
    setIsWhatsAppAllowed(state, action) {
      state.isWhatsAppAllow = action.payload;
    },
    setWindowWidth(state, action) {
      state.windowWidth = window.innerWidth;
    },
    setDepartmentName(state, action) {
      state.departMentName = action.payload;
    },
    setDate(state, action) {
      state.date = action.payload;
    },
    setUserEmail(state, action) {
      state.userEmail = action.payload;
    },
    setPathHistory(state, action) {
      state.pathHistory = action.payload;
    },
    setFinancialYearDetails(state, action) {
      state.financialYearDetails = action.payload;
    },
    setFullScreen(state, action) {
      state.isFullScreen = action.payload;
    },
    setmasterTablePrifx(state, action) {
      state.masterPrefix = action.payload;
    },
    setdefaultProfileSetting(state, action) {
      state.profileDefaultSetting = action.payload;
    },
    setTodayIssueStatus(state, action) {
      state.todayIssueStatus = action.payload;
    },
    setlatitudeRedux(state, action) {
      state.latitude = action.payload;
    },
    setlongitudeRedux(state, action) {
      state.longitude = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setCompanyAddress(state, action) {
      state.companyAddress = action.payload;
    },
    setBillingAccountDetails(state, action) {
      state.billIngAccount = action.payload;
    },
    setStationName(state, action) {
      state.stationName = action.payload;
    },
    setPrefix(state, action) {
      state.prefix = action.payload;
    },
    setStationID(state, action) {
      state.station_id = action.payload;
    },
    setBillingGuestDetails(state, action) {
      state.billingGuest = { ...action.payload };
      localStorage.setItem(
        'BillingGuestDetails',
        JSON.stringify({ ...action.payload })
      );
    },
    setfirebaseStatusField(state, action) {
      state.firebaseStatusField = action.payload;
      localStorage.setItem('statusField', action.payload);
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    resetProfile(state, action) {
      state = profileIntialState;
    },
  },
});

export default Profile.reducer;

export const {
  setBillingGuestDetails,
  setUserName,
  setDate,
  setfirebaseStatusField,
  setPathHistory,
  setWindowWidth,
  setUserEmail,
  setFinancialYearDetails,
  setFullScreen,
  resetProfile,
  setTodayIssueStatus,
  setIsProductionModuleAllowed,
  setCompany,
  setUserCode,
  setlatitudeRedux,
  setlongitudeRedux,
  setPrefix,
  setIsWhatsAppAllowed,
  setUserType,
  setdefaultProfileSetting,
  setCompanyAddress,
  setStationName,
  setStationID,
  setmasterTablePrifx,
  setUserpaswrd,
  setBillingAccountDetails,
  setDepartmentName,
} = Profile.actions;

export const selectUserName = (state) => state.profile.userName;
export const selectUserCode = (state) => state.profile.userCode;
export const selectCompany = (state) => state.profile.company;
export const selectPrefix = (state) => state.profile.prefix;
export const selectGuestDetails = (state) => state.profile.billingGuest;
export const selectStationId = (state) => state.profile.station_id;
export const selectStationName = (state) => state.profile.stationName;
export const selectAccountDetails = (state) => state.profile.billIngAccount;
export const selectCompanyAddress = (state) => state.profile.companyAddress;
export const selectUserType = (state) => state.profile.userType;
export const selectLatitude = (state) => state.profile.latitude;
export const selectLongitude = (state) => state.profile.longitude;
export const selectDefultProfileSetting = (state) =>
  state.profile.profileDefaultSetting;
export const selectTodayIssueStatus = (state) => state.profile.todayIssueStatus;
export const selectTableMasterPrefix = (state) => state.profile.masterPrefix;
export const selectFullScreen = (state) => state.profile.isFullScreen;
export const selectDate = (state) => state.profile.date;
export const selectFinancialYearDetails = (state) =>
  state.profile.financialYearDetails;
export const selectUserEmail = (state) => state.profile.userEmail;
export const selectWidth = (state) => state.profile.windowWidth;
export const selectPathHistory = (state) => state.profile.pathHistory;
export const selectDepartmentName = (state) => state.profile.departMentName;
export const selectIsProductionModuleAllwed = (state) =>
  state.profile.isProductionModuleAlowed;

export const selectIsWhatsAppAllowed = (state) => state.profile.isWhatsAppAllow;
export const selectFirebaseStatusField = (state) =>
  state.profile.firebaseStatusField;
